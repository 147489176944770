#process {
    background-color: var(--white);
}
.process-container {
    height: 850px;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* .process-title {
    width: 320px;
} */
.process-container h2 {
    width: 100%;
    text-align: center;
}
.process-box-container {
    height: 530px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.process-box {
    width: 320px;
    height: 150px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
  
    border-radius: 10px;
    background-color: white;
    box-shadow: var(--shadow1);
  }
.process-number, 
.process-text {
    margin: 0px;
    max-height: 80px;
}

.process-text {
    text-align: left;
    margin-left: 20px;
    align-self: center;
}

@media only screen and (min-width: 800px){
    .process-container {
        flex-direction: row;
        margin: auto;
    }
    .process-container h2 {
        text-align: right;
    }
    .process-box-container {
        max-width: 1200px;
    }
}
@media only screen and (min-width: 890px){
    .process-container {
        width: 864px;
    }
}