#header {
  z-index: 1;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
}
#nav,
#login-nav {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#login-nav {
  justify-content: center;
}
.nav-logo {
  cursor: pointer;
  margin-left: 10px;
  font-weight: 900;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.nav-logo:hover {
  color: var(--green);
}
.nav-links {
  display: none;
}
.nav-menu-header {
  display: flex;
  align-items: center;
}
.nav-mobile {
  display: block;
}
.nav-mobile-btn {
  width: 30px;
  cursor: pointer;
}
.nav-links-mobile {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.nav-links-mobile a {
  padding: 10px;
}
.nav-buttons {
  display: none;
}
.sign-right {
  display: inline-flex;
  margin: 20px 10px;
}
.sign-right p {
  margin: 0 10px 0 0;
}
.signout-link {
  margin: 20px 5px;
  font-size: 12px;
}
.nav-mobile-menu {
  display: none;
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 110%;
  left: 0%;
  background-color: var(--black-alpha);
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}
.nav-mobile-menu-content {
  margin: 30px;
  padding: 50px 0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light-gray);

}
.on-nav {
  display: block;
}

@media only screen and (min-width: 650px) {
  .nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-mobile {
    display: none;
  }
  .nav-mobile-btn {
    display: none;
  }
  .nav-right {
    margin-right: 10px;
  }
  .signout-link {
    margin: 20px 10px;
    font-size: 14px;
  }
  .nav-links {
    display: block;
  }
  .nav-link {
    font-size: 14px;
    margin: 0 10px;
  }
  .nav-link:hover {
    color: var(--green);
  }
}
@media only screen and (min-width: 990px) {
  #nav,
  #login-nav {
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1300px) {
  #nav,
  #login-nav {
    width: 80%;
  }
  
}
