#banner-clase-muestra {
    margin: 50px 0 0 0;
}
.banner-clase-muestra {
    margin: auto;
    width: 90%;
    height: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner-clase-muestra h2 {
    margin: 0;
}
.banner-clase-muestra h2,
.banner-clase-muestra p {
    max-width: 900px;
    color: white;
}
.banner-clase-muestra p {
    font-size: 1.2rem;
}
@media only screen and (min-width: 620px){
    .banner-clase-muestra p {
        font-size: 1.6rem;
    }
}
