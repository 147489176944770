#about-me {
    margin: 70px 10px;
}
/* .about-me-container {
    height: 850px;
} */
.about-me-box {
    padding: 30px 15px;
    margin: auto;
    /* height: 700px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--bg-light-gray);
    border-radius: 10px;
    /* box-shadow: var(--shadow2); */
}
.about-me-text {
    width: 90%;
}
.about-me-text h2, 
.about-me-text h3,
.about-me-text p {
    text-align: left;
}
.about-me-text h2 {
    max-width: 40%;
    margin-bottom: 5px;
}
.about-me-text h3 {
    margin: 5px 0;
    line-height: 1.5;
}
.about-me-text p {
    margin: 0;
    line-height: 1.7;
}

.about-me-picture img {
    max-width: 380px;
}

@media only screen and (min-width: 620px){
    .about-me-text h2 {
        max-width: 50%;
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .social-icons {
        margin: 50px 60px;
        display: flex;
        justify-content: space-evenly;
    }
    .about-me-picture img {
        max-width: 300px;
        margin: 0 20px 0 0;
    }
}
@media only screen and (min-width: 650px){
    .about-me-container {
        max-width: 1100px;
        margin: auto;
    }
    .about-me-box {
        max-width: 790px;
         /* margin: auto; */
        flex-direction: row;
        justify-content: space-evenly;
    }
    .about-me-text {
        max-width: 420px;
    }
    .social-icons {
        margin: 50px 80px;
    }
}

@media only screen and (min-width: 950px){
    .social-icons {
        margin: 50px 240px;
    }
}


/* Scroll top button */
#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: red; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
  }
  
  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }