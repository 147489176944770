.dropdown {
  /* position: relative; */
  display: inline-block;
  cursor: pointer;
}
/* Dropdown Button Desktop */
.dropbtn {
  text-align: center;
  padding: 4px 16px;
  font-size: 1rem;

  background: var(--white);
  color: var(--dark-gray);

  text-transform: unset;
  /* font-weight: 400; */

  border: solid 1px var(--light-gray);
  border-radius: 10px;
  box-shadow: none;
}
.icon-down {
  width: 20px;
}
/* Dropdown Content Language Menu (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 115px;
  border-radius: 10px;
  box-shadow: var(--shadow-solid);
  z-index: 1;
  border: solid 1px var(--light-gray);
}
.dropdown-content p {
  color: var(--dark-gray);
}

.dropdown-content p:active {
  color: var(--dark-gray);
  background-color: var(--bg-light-purple);
  border-radius: 10px;
}
.on {
  display: block;
}
