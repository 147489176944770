#beneficios {
    background: var(--light-gray);
    padding: 60px 0;
  }
  #beneficios h2 {
    margin-bottom: 0px;
    text-align: left;
  }
  .beneficios-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .beneficios-container h3 {
    width: 80%;
  }
  .beneficios-box-container {
    min-width: 250px;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .beneficios-box {
    max-width: 300px;
    height: 220px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    border-radius: 10px;
    background-color: var(--white);
  }
  .beneficios-box p, 
  .beneficios-box h4 {
    margin: 0px;
    text-align: left;
  }
  .beneficios-box p {
    height: 60px;
    margin-top: 10px;
  }
  .beneficios-desktop {
    display: none;
  }
  .beneficios-img {
   display: flex;
   align-items: flex-start;
  }
  .beneficios-img svg{
    width: 30px;
    height: 30px;
    color: var(--green);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-beneficios {
    height: 20px;
    color: var(--white);
  }
  
  
  
  @media only screen and (min-width: 620px){
    .beneficios-box-container {
      height: auto;
      flex-flow: row wrap;
    }
    .beneficios-container {
      width: 85%;
      margin: auto;
    }
    .beneficios-container h2 {
      max-width: 954px;
      align-self: flex-start;
    }
    .beneficios-box {
      margin: 40px 0;
    }
    .line-top{
      content: " ";
      /* width: 100%; */
      height: 240px;
      position: absolute;
      top: 486px;
      left: 0px;
      background: url(../../Assets/Shapes/Rectangle-purple-top-desktop.svg) no-repeat center;
      background-size: cover;
    }
    .line-bottom {
      height: 200px;
      top: 50px;
      left: 0px;
      background: url(../../Assets/Shapes/Rectangle-purple-white-bottom-desktop.svg) no-repeat center;
      background-size: cover;
    }
  }
  @media only screen and (min-width: 950px){
    .beneficios-desktop {
      display: flex;
    }
  }
  @media only screen and (min-width: 1400px){
    .beneficios-box-container {
      width: 1355px;
    }
  }